<template>
  <div class="page-conference-detail" style="margin-top: -10px;margin-bottom: -80px;">
      <div class="inner-box" id="scrollTop">
        <!-- 菜单锚点 -->
        <el-menu
          id="searchBar"
          :default-active="currentId"
          class="el-menu-vertical-demo"
          :class="scroll ? 'fixed' : ''"
          :default-openeds="openeds"
          @select="handleSelect"
        >
          <div v-for="item in menus" :key="`item${item.id}`">
            <el-submenu v-if="item.children.length > 0" :index="item.id">
              <template slot="title">
                <el-image
                  class="menuImg"
                  :src="
                    keyPath.includes(item.id)
                      ? require(`@/assets/img/${item.icon}.png`)
                      : require(`@/assets/img/${item.iconGray}.png`)
                  "
                  fit="fill"
                ></el-image>
                <span slot="title" class="menusTitle">
                  {{ item.parentName }}
                </span>
              </template>
              <div>
                <el-menu-item-group>
                  <el-menu-item
                    v-for="i in item.children"
                    :key="i.id"
                    :index="i.id"
                    @click="addMenu(i.id)"
                  >
                    <span slot="title" class="menusTitle">
                      {{ i.name }}
                    </span>
                  </el-menu-item>
                </el-menu-item-group>
              </div>
            </el-submenu>
            <template v-if="item.children.length <= 0">
              <el-menu-item :index="`${item.id}`" @click="addMenu(item.id)">
                <el-image
                  class="menuImg"
                  :src="
                    keyPath.includes(item.id)
                      ? require(`@/assets/img/${item.icon}.png`)
                      : require(`@/assets/img/${item.iconGray}.png`)
                  "
                  fit="fill"
                ></el-image>
                <span slot="title" class="menusTitle">
                  {{ item.parentName }}
                </span>
              </el-menu-item>
            </template>
          </div>
        </el-menu>
        <!-- 文案 -->
        <div class="content" ref="content">
          <div class="box" style="margin-bottom: 50px;">
            <div v-for="item in content" :key="`content${item.id}`">
              <div
                class="title1"
                :class="currentId === item.id ? 'origin' : ''"
                :id="`scroll${item.id}`"
              >
                {{ item.title1 }}
              </div>
              <div class="title2Box" v-if="item.infoList">
                <div v-for="i in item.infoList" :key="`info${i.id}`">
                  <div
                    :id="`scroll${i.id}`"
                    class="title2"
                    :class="currentId === i.id ? 'origin' : ''"
                  >
                    {{ i.title2 }}
                  </div>
                  <div class="info" v-html="i.content" @click="jumpqualif"></div>
                </div>
              </div>
              <div class="info top" v-else v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      scroll: false,//滚动定位
      currentId: '1-1',
      currentIndex: 0,
      keyPath: ['1', '1-1'],//切换图片
      openeds: ['1', '2', '3', '4', '5'],
      content: [
        {
          id: '1', title1: '入驻前准备', infoList: [
            { id: '1-1', title2: '了解信息', content: '1.品牌：国美家枚举的重点招募品牌，您也可以推荐优质品牌给京东，部分类目不限定品牌招商</br>2.企业：合法登记的公司，并且能够提供国美家入驻要求的所有相关文件</br>3.资质要求：详见《国美家招商基础资质标准》及各类目资质标准' },
            { id: '1-2', title2: '准备资料', content: '1.点击查询 <span style="color:#ee9118">入驻所需资质和费用</span></br>2.准备的资料请加盖开店公司公章（鲜章）</br>3.如申请材料缺少，会退回给您重新提交，建议您事先准备齐全资料，一次性通过审核' },
          ]
        },
        { id: '2', title1: '填写入驻资料', title2: '', content: '提交的入驻资料包括： (需招商提供)</br>1.选择店铺类型/品牌/类目</br>2.填写品牌信息</br>3.填写企业信息' },
        {
          id: '3', title1: '审核', infoList: [
            { id: '3-1', title2: '国美家审核', content: '1.资质真实有效</br>2.授权有效，链路完整</br>3.生产、经营范围、产品安全性资质完整，符合国家行政法规许可要求</br>4.审核周期：初审约2个工作日，复审约5个工作日' },
            { id: '3-2', title2: '审核进度查询', content: '商家可查询入驻审核进度，入驻过程中的重要信息，我们会以短信/邮件形式告知您。' },
          ]
        },
        {
          id: '4', title1: '合同签订', infoList: [
            { id: '4-1', title2: '合同进度查询', content: '1、招商发起合同，商户在商户端确认电子合同。</br>2、合同签署进度可咨询招商专员进行咨询，合同审批结束之后会以短信形式通知商户。' },
          ]
        },
        { id: '5', title1: '开店及缴费', title2: '', content: '1、请使用提交资料的手机号获取验证码进行登录。</br>2、在待办任务中找到缴费管理，进行缴费。</br>3、缴费成功之后，店铺已开店成功。</br>4、开店成功之后请登录商户端查看开店配置进行上线操作。' },
      ],
      menus: [
        { id: '1', href: 'scroll1', parentName: '入驻准备', icon: 'process1', iconGray: 'processBg1', children: [{ id: '1-1', name: '了解信息', href: 'scroll0-1' }, { id: '1-2', name: '准备资料', href: 'scroll0-2' }] },
        { id: '2', href: 'scroll2', parentName: '填写入驻资料', icon: 'process2', iconGray: 'processBg2', children: [] },
        { id: '3', href: 'scroll3', parentName: '审核', icon: 'process3', iconGray: 'processBg3', children: [{ id: '3-1', name: '国美家审核', href: 'scroll2-1' }, { id: '3-2', name: '审核进度查询', href: 'scroll2-2' }] },
        { id: '4', href: 'scroll4', parentName: '合同签订', icon: 'process4', iconGray: 'processBg4', children: [{ id: '4-1', name: '合同进度查询' }] },
        { id: '5', href: 'scroll5', parentName: '开店及缴费', icon: 'process5', iconGray: 'processBg5', children: [] },
      ]
    };
  },
  // mounted () {
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  // // 销毁监听
  // beforeDestroy () {
  //   window.removeEventListener('scroll', this.handleScroll)
  // },
  methods: {
    handleSelect (key, keyPath) {
      this.keyPath = keyPath
    },
    // handleScroll () {
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //   var offsetTop = document.querySelector('#searchBar').offsetTop;
    //   console.log(scrollTop, offsetTop)
    //   if (scrollTop >= 900) {
    //     this.scroll = false
    //     //offsetTop = 300 - Number(scrollTop);
    //     document.querySelector('#searchBar').style.top = offsetTop + 'px';
    //   } else {
    //     this.scroll = true
    //     document.querySelector('#searchBar').style.top = offsetTop + 'px';

    //     //document.querySelector('#searchBar').style.top = '20px';
    //   }
    // },
    jumpqualif (e) {
      if (e.target.innerText === '入驻所需资质和费用')
        this.$router.push({ name: 'QualificationInfo' })
    },
    addMenu (id) {
      let currentY = document.getElementById(`scroll${this.currentId}`).scrollTop || document.body.scrollTop
      let targetY = document.getElementById(`scroll${id}`).offsetTop
      this.currentId = id
      targetY -= 214
      console.log(currentY, targetY)
      this.scrollAnimation(currentY, targetY)
    },
    scrollAnimation (currentY, targetY) {
      // 获取当前位置方法
      // 计算需要移动的距离 
      let needScrollTop = targetY - currentY
      let _currentY = currentY
      setTimeout(() => {
        // 一次调用滑动帧数，每次调用会不一样
        const dist = Math.ceil(needScrollTop / 10)
        _currentY += dist
        this.$refs.content.scrollTo(_currentY, currentY)
        // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.scrollAnimation(_currentY, targetY)
        } else {
          this.$refs.content.scrollTo(_currentY, targetY)
        }
      }, 10)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../mall/assets/styles/base.scss';
::v-deep .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 0 10px 30px 0 rgba(201, 201, 201, 0.2);
}
.page-conference-detail {
  .inner-box {
    max-width: 1200px;
    margin: 0 auto;
    //position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .href {
      color: #333333;
    }
    .content {
      width: 760px;
      height: 660px;
      background: #ffffff;
      border-radius: 4px;
      margin-left: 340px;
      padding: 0 50px 0 50px;
      font-family: MicrosoftYaHei;
      margin-left: 40px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:rgba(0, 0, 0, 0.3);
        box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.5);
      }
      .title1 {
        margin-top: 40px;
        height: 24px;
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 24px;
      }
      .title2 {
        margin-top: 40px;
        margin-bottom: 10px;
        height: 24px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 24px;
      }
      .info {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 20px;
      }
      .top {
        margin-top: 40px;
      }
    }
  }
  .origin {
    color: #ee9118 !important;
  }
}
.fixed {
  position: fixed;
}
.menuImg {
  width: 20px;
  height: 20px;
}
.menusTitle {
  margin-left: 21px;
}
::v-deep {
  .el-menu {
    border-right: 0;
  }
  .el-menu-vertical-demo {
    width: 300px;
    height: 660px;
    background: #ffffff;
    border-radius: 4px;
  }
  .el-submenu__title {
    height: 50px;
    display: flex;
    align-items: center;
  }
  .el-menu-item-group__title {
    padding-top: 0;
  }
  .el-menu-item.is-active {
    color: #ee9118;
  }
  .el-submenu.is-active .el-submenu__title {
    color: #ee9118;
  }
  .el-submenu__title:hover,
  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #efefef;
  }
}
</style>